.dot_carousel {
    position: relative;
    left: -9999px;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #4d75b8;
    margin: 25px auto 0px;
    color: #4d75b8;
    box-shadow: 9984px 0 0 0 #4d75b8, 9999px 0 0 0 #4d75b8, 10014px 0 0 0 #4d75b8;
    animation: dotCarousel 1.5s infinite linear;
  }
  
  @keyframes dotCarousel {
    0% {
      box-shadow: 9984px 0 0 -1px #4d75b8, 9999px 0 0 1px #4d75b8, 10014px 0 0 -1px #4d75b8;
    }
    50% {
      box-shadow: 10014px 0 0 -1px #4d75b8, 9984px 0 0 -1px #4d75b8, 9999px 0 0 1px #4d75b8;
    }
    100% {
      box-shadow: 9999px 0 0 1px #4d75b8, 10014px 0 0 -1px #4d75b8, 9984px 0 0 -1px #4d75b8;
    }
  }