@font-face {
  font-family: "Archivo";
  src: url("assets/fonts/Archivo-Bold.ttf");
}
@font-face {
  font-family: "Archivo";
  src: url("assets/fonts/Archivo-Medium.ttf");
}

@font-face {
  font-family: "Archivo";
  src: url("assets/fonts/Archivo-Regular.ttf");
}

@font-face {
  font-family: "Roboto Flex";
  src: url("assets/fonts/RobotoFlex.ttf");
}

html {
  height: 100%;
  width: 100%;
  overscroll-behavior: none;
}

body {
  min-width: 100vw;
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: "Archivo", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;
}

html, body, #root, #root > div {
  margin: 0;
  box-sizing: border-box;
  background-repeat: repeat;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  display: none;
}